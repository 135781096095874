
export const CommonData = {

  production: true,
  version: "1.4",
  storeVersion : "1.0.4L",
  platform : 0,
  debug: false, 
  lang : "de",  
  activateLangDetection: true,
  //time in milliseconds
  connectionCheckInterval: 10000,
  //time in milliseconds
  fetchDataInterval: 65000,
  //time in milliseconds. 5 seconds
  requestTimeout : 5000,  
  url : "https://www.go4q.mobi/go4qmobile/",
  // url : "http://192.168.150.62/go4qmobile/",
  testUrl: "test",
  decryptUrl: "decryptCode",
  voucherCodeControlUrl : "voucherCodeControl",
  authenticateUrl : "authenticateRetailer",  
  groupVoucherControlUrl : "groupVoucherControl",
  groupListUrl : "groupList",
  retailerVouchersUrl : "retailerVouchers",
  resetPasswordUrl : "",
  isWebApp: false,
  constants : {
    CURRENT_USER: 'currentUser',
    TOKEN: 'userToken',
    STOREID : 'storeid',
    PROJECTS: 'projects',
    RETAILERS : 'retailers',
    SAVED_PASSWORD : 'password',
    PASSWORD_CONFIRMED: 'password_confirmed'    
  }
}