export class RetailerData {
    // retailer id
    RId : number = 0;   
    //company name
    CN : string  = "";
   //email
    EM : string = "";
    //username
    UN : string = "";
    //Admin Id
    AId:number = 0;
    //in a group    
    ING: boolean = false;
    //group master Id
    GMId: number = 0;
    /** is master */
    ISM:boolean = false;
    //group Id
    GRPId: number = 0;
    //activate part redeem
    APR: number = 0
    
}