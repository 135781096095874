import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CommonData } from '../common/CommonData';
import { Base64 } from 'js-base64';
import { timeout } from 'rxjs/operators';

//const httpOptions = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'});
//const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');


@Injectable({
  providedIn: 'root'
})
export class BaseManagerService {
  
  //let erroresponse = new IsiTimeResponse();   
  constructor(private http: HttpClient) {
    
  }


  sendPostRequest(url: string, req: any ) : Observable<any> {

    if(CommonData.debug){
      console.log('POST REQUEST: ' + JSON.stringify(req));
    }

    let base64ReqString = Base64.encode(JSON.stringify(req));        
    const payload = new HttpParams().set('data', base64ReqString);        
    return this.http.post(url, payload, { responseType: 'text'});
  }


  sendGetRequest(url: string, req: any ){
    //let base64ReqString = window.btoa(unescape(encodeURIComponent(req)));
    if(CommonData.debug){
      console.log('REQUEST: ' + JSON.stringify(req));
    }      
    let base64ReqString = Base64.encode(JSON.stringify(req));
    
    //const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
  
    return this.http.request('GET',url + "?data=" + base64ReqString, {responseType:'text'});        
  }

  httpGet(url: string){
    return this.http.get(url).pipe(
      timeout(CommonData.requestTimeout) //5 seconds
    );  
  }

  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
 
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
 
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
