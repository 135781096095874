import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommonData } from './common/CommonData';
import { Globals } from './common/globals';
import { RetailerData } from './common/RetailerData';
import { AuthenticateService } from './services/authenticate.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  appVersion: any;
  online: any;
  currentUser: RetailerData;
  isMaster: boolean = false;
  public shopName: string = 'Shop';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private globals: Globals,
    private authenticationService: AuthenticateService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#4b0f0f');
      this.splashScreen.hide();


      this.appVersion = CommonData.storeVersion;
      this.online = this.globals.cnxExist;

      if (this.platform.is('android') || this.platform.is('phablet')) {
        this.globals.platform = "android";
        CommonData.platform = 1;
      }
      else if (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone')) {
        this.globals.platform = "ios";
        CommonData.platform = 2;
      }
      else {
        this.globals.platform = "windows";
        CommonData.platform = 3;
        CommonData.isWebApp = true;
      }

      //load language
      this.translate.addLangs(['en', 'de']);

      if (CommonData.activateLangDetection) {
        if (this.translate.getBrowserLang() !== undefined) {
          const browserLang = this.translate.getBrowserLang();
          this.translate.use(browserLang.match(/en|de/) ? browserLang : 'de').subscribe(data => {
            this.globals.lang = this.translate.currentLang;
            /*if(CommonData.debug){
              console.log("Appcomponent: translateService.use success: " + JSON.stringify(data));        
            }*/
          }, error => {
            if (CommonData.debug) {
              console.log("Appcomponent: translateService.use Error: " + error);
            }
          });
        } else {
          this.translate.use(CommonData.lang).subscribe(data => {
            this.globals.lang = CommonData.lang;
            if (CommonData.debug) {
              console.log("Appcomponent: translateService.use success: " + JSON.stringify(data));
            }
          }, error => {
            if (CommonData.debug) {
              console.log("Appcomponent: translateService.use Error: " + error);
            }
            this.globals.lang = CommonData.lang;
            this.translate.setDefaultLang(CommonData.lang);
          });
        }
      }
      else {
        this.translate.setDefaultLang(CommonData.lang);
        this.globals.lang = CommonData.lang;
      }

      try {
        this.currentUser = JSON.parse(localStorage.getItem(CommonData.constants.CURRENT_USER));
        this.shopName = this.currentUser.CN.substring(0, 30);
      } catch (ex) {
        if (CommonData.debug) {
          console.log("Appcomponent: shopName Ex: " + ex);
        }
      }

    });
  }
  ngOnInit() {
    if (this.currentUser) {
      if (this.currentUser.ISM) {
        this.isMaster = true;
      }
      try {
        this.shopName = this.currentUser.CN.substring(0, 30);
      } catch (ex) {
        if (CommonData.debug) {
          console.log("Appcomponent: shopName Ex: " + ex);
        }
      }
    }

    let plats = this.platform.platforms();

    for (let index = 0; index < plats.length; index++) {
      const element = plats[index];
      if (element == 'mobileweb' || element == 'desktop') {
        if (CommonData.debug) {
          console.log("App is Webapp");
        }

        CommonData.isWebApp = true;
        this.globals.platform = "windows";
        CommonData.platform = 3;
      }
    }

  }
  public logout() {
    this.authenticationService.logout();
    this.router.navigate(['start']);
  }
}