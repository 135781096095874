export class Retailer {
    // retailer id
    RId : number = 0;
   //gender
   GEND : string = "";
   // retailer firstname
   FN: string = "";
   //retailer lastname
    LN : string = "";
   //company name
    CN : string  = "";
   //email
    EM : string = "";
   //street and house number
   STRT : string = "";
   //plz
   PLZ : string = "";
   //town
   TN : string = "";
   //password
   PW : string = "";
   //vat number
   VN : string = "";
   // status
   ST: number = 0;
   //phone
   PH : string = "";
   // password changed
    PWC :number = 0;
   // created at
    CAT : string = "";
   // created by
    CBY : string = "";
   // last change
   LCH : string = "";
   // last changed by
    LCBY : string = "";
}