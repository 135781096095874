import { Injectable } from '@angular/core';
import { CommonData } from '../common/CommonData';
import { Retailer } from '../common/Retailer';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseManagerService } from './base-manager.service';
import * as CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';
import { tap } from 'rxjs/operators';
import { RetailerData } from '../common/RetailerData';
import { Globals } from '../common/globals';
import { ClientInfo } from '../common/ClientInfo';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  retailer : Retailer;
  
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

    constructor(private baseManager: BaseManagerService, private globals: Globals) {
        this.currentUserSubject = new BehaviorSubject<RetailerData>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.retailer = new Retailer();
        
    }

    public get currentUserValue(): RetailerData {
        return this.currentUserSubject.value;
    }

    public isLoginValid() : boolean {
        if(!localStorage.getItem('userToken')){
            return false;
        }
        return true;
    }

    login(username: string, password: string) : Observable<any> {
        let date = new Date();
        let pwHash = CryptoJS.MD5(password).toString();
        let tId = date.getMilliseconds() + "";
        this.retailer.EM = username;
        this.retailer.PW = pwHash;
        let clientinfo = new ClientInfo();
        clientinfo.OS = CommonData.platform;
        let req ={
            'UN' : username,
            'RRT' : pwHash,
            'CI' : clientinfo,
            'TId': tId ,
            'CT' : ""
        }
       
        let url = CommonData.url + CommonData.authenticateUrl;
        return this.baseManager.sendGetRequest(url,req)
        .pipe(
            tap(
                data =>{                    
                     
                     let res = JSON.parse(Base64.decode(data));                     

                     if (res.TR === "OK") {                        
                        if(CommonData.debug){
                            console.log("LOGIN RESPONSE DECODED: " + JSON.stringify(res));
                        }                                           

                        let retailerData: RetailerData = new RetailerData();
                        retailerData.CN = res.retailer.CN;
                        retailerData.RId = res.RId;
                        retailerData.ISM = res.ISM;
                        retailerData.UN = username;
                        retailerData.EM = res.retailer.EM;
                        retailerData.AId = res.AId;
                        retailerData.ING = res.retailer.ING;
                        retailerData.GMId = res.retailer.GMId;
                        retailerData.GRPId = res.retailer.GRPId;
                        retailerData.APR = res.APR;
                         // store user details and token in local storage to keep user logged in between page refreshes
                         localStorage.setItem(CommonData.constants.CURRENT_USER, JSON.stringify(retailerData));
                         localStorage.setItem(CommonData.constants.TOKEN, pwHash);                                              
                         this.currentUserSubject.next(retailerData);
                     }       
                }
            )
        );        
    }    

    logout() {
        
        // remove user from local storage to log user out
        localStorage.removeItem(CommonData.constants.CURRENT_USER);
        localStorage.removeItem(CommonData.constants.TOKEN);
        //localStorage.removeItem(CommonData.constants.AUTO_PAUSE);        
        localStorage.clear();
        this.currentUserSubject.next(null);        
    }    
}
