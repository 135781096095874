import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',    
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),canActivate:[AuthGuard]},
  { path: 'login', loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)},
  {path: 'purchasedvouchers', loadChildren: () => import('./purchasedvouchers/purchasedvouchers.module').then( m => m.PurchasedvouchersPageModule),canActivate:[AuthGuard]},
  { path: 'redeemedvouchers', loadChildren: () => import('./redeemedvouchers/redeemedvouchers.module').then( m => m.RedeemedvouchersPageModule),canActivate:[AuthGuard]},
  { path: 'scanner', loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule),canActivate:[AuthGuard]},
  { path: 'start', loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)},
  {path: 'uservouchercheck', loadChildren: () => import('./uservouchercheck/uservouchercheck.module').then( m => m.UservouchercheckPageModule)},
  {path: 'voucheractivate', loadChildren: () => import('./voucheractivate/voucheractivate.module').then( m => m.VoucheractivatePageModule),canActivate:[AuthGuard]},
  { path: 'vouchercheck', loadChildren: () => import('./vouchercheck/vouchercheck.module').then( m => m.VouchercheckPageModule),canActivate:[AuthGuard] }
  //{ path: 'voucher-code-form', loadChildren: () => import('./voucher-code-form/voucher-code-form.module').then( m => m.VoucherCodeFormPageModule)}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
