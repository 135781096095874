export class ClientInfo {
    OS: number =3;
    //the Application version
    AV: string ="2.0"; 
    //Application ID
    AID: string = "ISICADO_WEB_APP"; 
    //language
    LG: string = "DE";
    //time zone
    TZ : string = "europe/berlin";
    // optional Go4Q Api-Key
    AK : String = "";
    // current timestamp
    CT : number = 0;   
  }